import React from 'react';
import { graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'
import styled from "styled-components"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"


import Layout from '../components/layout';
import SEO from '../components/seo';
import ContactForm from '../components/contact-form';



const Contact = ( { data } ) => {
    return (  
        <Layout>
           <SEO 
            pageType="website"
            linkTitle={data.contact.contactPageLinkTitle}
            pageDescription={data.contact.contactPageLinkDescription}
            pageURL={`${data.site.siteMetadata.url}contact`}
            pageTitle={data.contact.contactPageTitle}
            linkImage={`https:${data.contact.contactPagePreviewImage.file.url}`}
            />
            <TopImage fluid={data.contact.contactPageBannerImage.fluid}>
                <TopBanner>
                    <ContactHeadline>{data.contact.contactPageBannerTitle}</ContactHeadline>
                </TopBanner>
            </TopImage>
            <ContactForm />
            <BackgroundImage fluid={data.contact.contactUpperImage.fluid}>
                <ImageBanner>  
                    <AddressBanner>
                    <AddressHeadline >{data.contact.contactHeadline}</AddressHeadline>
                    <Address>{documentToReactComponents(data.contact.contactAddress.json)}</Address>
                    <MediaEnquiresContact>{data.contact.contactPhoneNumber}</MediaEnquiresContact>
                    <MediaEnquiresContact style={{marginTop: "10px"}}>{data.contact.contactEmail}</MediaEnquiresContact>
                    <MediaEnquiresMessage>{data.contact.membershipMessage}</MediaEnquiresMessage>
                    <MediaEnquiresContact>{data.contact.membershipEmail}</MediaEnquiresContact>
                    <MediaEnquiresMessage>{data.contact.mediaMessage}</MediaEnquiresMessage>
                    <MediaEnquiresContact>{data.contact.mediaContact}</MediaEnquiresContact>

                    </AddressBanner>
                </ImageBanner>
            </BackgroundImage>
           
        </Layout>
    );
}
 
export default Contact;

export const pageQuery = graphql`
    query {
        contact:contentfulContactPage(contentful_id: {eq: "7hUVmm3hBkFmi0a3G3Bhs7"}) {
            id
            contactPageTitle
            contactPageLinkTitle
            contactPageLinkDescription
            contactPagePreviewImage {
              file {
                url
              }
            }
            contactPageBannerImage {
              fluid(maxWidth: 2000) {
                ...GatsbyContentfulFluid
              }
            }
            contactPageBannerTitle
            contactUpperImage {
              fluid(maxWidth: 2000) {
                ...GatsbyContentfulFluid
              }
            }
            contactHeadline
            contactAddress {
              json
            }
            contactEmail
            contactPhoneNumber
            membershipMessage
            membershipEmail
            mediaMessage
            mediaContact
            contactPageLowerImage {
                fluid(maxWidth: 2000) {
                  ...GatsbyContentfulFluid
                }
              }
        }
        site {
            siteMetadata {
                url
            }
        }
    }
`

const TopImage = styled(BackgroundImage)`
    margin-top: 80px !important;

    @media (max-width: 768px) {
        margin-top: 70px !important;
    }
`

const TopBanner = styled.div`
    height: 50vh;
    margin-top: 68px;
    padding-top: 60px;
    padding-bottom: 60px;
    background-image: linear-gradient(35deg, #004225, rgba(0, 66, 37, 0.3));
    display: flex;
    justify-content: center;
    align-items: center !important;

    @media (max-width: 768px) {
        height: 40vh;
    }

    @media (max-width: 768px) {
        height: 30vh;
    }
`

const ContactHeadline = styled.h1`
    color: #FFFFFF;
    text-align: center;
    font-size: 38px;
    font-weight: 900;
    font-family: 'Raleway', sans-serif !important;
    padding: 10px;
    display: inline;
    @media (max-width: 450px) {
    font-size: 9vw;
    }
`

const AddressBanner = styled.div`
    color: #ffffff !important;
    background-color: #004225;
    padding: 60px 20px;
    font-family: 'Cormorant Garamond', serif !important;
    text-align: center;
    

`

const AddressHeadline = styled.h1`
    font-family: inherit !important;
    font-weight: 900;
    font-size: 24px;
    margin-bottom: 20px;
    font-family: 'Raleway', sans-serif !important;
    font-style: none !important;

    @media (max-width: 450px) {
        font-size: 6.5vw;
    }
`

const Address = styled.div`
    font-family: inherit !important;
    line-height: 12px;
    font-weight: 600;
    font-size: 18px;
    font-style: italic;


    h3{
        font-weight: 800;
        font-size: 18px;
        font-family: inherit !important;
    }

    @media (max-width: 450px) {
        font-size: 5vw;
        h3 {
            font-size: 5vw;
        }
    }
`

const MediaEnquiresMessage = styled.p`
    font-family: 'Raleway', sans-serif !important;
    font-weight: 800;
    font-size: 18px;
    margin-top: 40px;
    margin-bottom: 10px;

    @media (max-width: 450px) {
        font-size: 5vw;
    }
`

const MediaEnquiresContact = styled.p`
    font-family: 'Cormorant Garamond', serif !important;
    font-style: italic;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0;

    @media (max-width: 450px) {
        font-size: 5vw;
    }
`

const ImageBanner = styled.div`
    background-color: rgba(0, 66, 37, 0.4);
    padding: 250px 0;
    @media (max-width: 450px) {
        padding: 75px 0;
    }
`