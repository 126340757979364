import React, { useState } from 'react';
import { Link, graphql, useStaticQuery, navigateTo } from "gatsby"
import styled from "styled-components"

const ContactForm = () => {

    const [formState, setFormState] = useState({
        firstName: "",
        lastName: "",
        email: "" 
    })

    const handleChange = e => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value,
        })
    }

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&")
    }

    const handleSubmit = e => {
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...formState })
          })
            .then(() => alert("Thank you!\n\nWe will be in touch with you soon."))
            .catch(error => alert(error));
    
          e.preventDefault();
          setFormState({
            firstName: "",
            lastName: "",
            email: ""  
          })
    }


    return ( 
        <Container>
            <FormHeadline>Keep updated &amp; join the debate</FormHeadline>
            <form style={{marginBottom: "0"}} onSubmit={handleSubmit} name="contact" method="POST" data-netlify="true">
                <input type="hidden" name="form-name" value="contact"/>
                <FormWrapper>
                    <FieldWrapper>
                        <Field 
                        placeholder="First Name"
                        id="firstName"
                        type="text"
                        name="firstName"
                        onChange={handleChange}
                        value={formState.firstName}
                        ></Field>
                    </FieldWrapper>
                    <FieldWrapper>
                        <Field 
                        placeholder="Last Name"
                        id="lastName"
                        type="text"
                        name="lastName"
                        onChange={handleChange}
                        value={formState.lastName}
                        ></Field>
                    </FieldWrapper>
                    <FieldWrapper>
                        <Field 
                        placeholder="Email Address"
                        id="email"
                        type="text"
                        name="email"
                        onChange={handleChange}
                        value={formState.email}
                        ></Field>
                    </FieldWrapper>
                    <FieldWrapper>
                        <SubmitButton>Sign Up</SubmitButton>
                    </FieldWrapper>
                </FormWrapper>
            </form>
            <Disclaimer>By clicking Sign Up I agree to the Frances Hutcheson Institute using the information I provide to keep me updated via email about The Institute’s work and opportunities to get involved. For more information see our <Link className="privacy-link" to="/privacy">Privacy Policy</Link>.</Disclaimer>
        </Container>

     );
}
 
export default ContactForm;

const Container = styled.div`
    width: 100%;
    background-color: #004225;
    padding: 50px 0;

    @media (max-width: 450px) {
        padding: 30px 0;
    }
`

const FormHeadline = styled.h1`
    color: #ffffff;
    font-weight: 900;
    font-size: 26px;
    font-family: 'Raleway', sans-serif !important;
    text-align: center;
    margin-bottom: 10px;
    line-height: 40px;
    padding: 30px 15px 10px 15px;

    .highlight{
        /* color: #0a3b7c;
        background-color: #ffffff;
        padding: 3px 5px;
        font-family: 'Montserrat', sans-serif;
        font-size: 26px;
        line-height: 40px; */
    }

    @media (max-width: 450px) {
        font-size: 6.8vw;
        .highlight{
            /* font-size: 6.8vw; */
        }
    }

`

const FormWrapper = styled.div`
    max-width: 1200px;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr!important;
    grid-template-rows: auto;
    padding: 0 40px;
    margin: 0 auto;

    @media (max-width: 769px) {
        grid-template-columns: 1fr !important;
    }

    @media (max-width: 450px) {
        grid-template-columns: 1fr !important;
        padding: 0px 20px !important;
    }

`

const FieldWrapper = styled.div`

`

const Field = styled.input`
    appearance: none;
    width: 100%;
    height: 36px;
    border-radius: 3px;
    border: 2px solid rgb(225, 225, 230);
    font-size: 14px;
    padding: 1px 2px 1px 17px;
    font-family: 'Cormorant Garamond', serif !important;
    font-style: italic;
`

const SubmitButton = styled.button`
    appearance: none;
    width: 100%;
    border-radius: 3px;
    height: 36px;
    cursor: pointer;
    background-color: #BEBEBE;
    border: 2px solid #BEBEBE;
    color: #ffffff !important;
    font-size: 13px;
    font-weight: 700;
    transition: all 300ms linear 0s ;

    &:hover{
        background-color: #707070;
        border: 2px solid #707070;
        transition: all 300ms linear 0s ;
    }
`

const Disclaimer = styled.p`
    max-width: 1200px;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    font-family: 'Raleway', sans-serif !important;
    text-align: center;
    padding: 30px 15px 30px 15px;
    line-height: 20px;

    .privacy-link{
        text-decoration: none;
        color: #ffffff;
        font-weight: 900;
        font-size: 14px;
        font-family: 'Raleway', sans-serif !important;
        text-align: center;
        border-bottom: 2px solid #004225;

        &:hover{
            border-bottom: 2px solid #ffffff;
            transition: all 300ms linear 0s ;
        }
    }

    @media (max-width: 450px) {
        padding: 20px 15px 30px 15px;
    }
`